'use client'

import {
	Popup as CPopup,
} from 'konsta/react';

export default function Popup({ popupOpened, onBackdropClickHook, children, ...rest }) {
	return (
        <CPopup
            opened={popupOpened} onBackdropClick={() => onBackdropClickHook()} {...rest} style={{ zIndex: 1001 }}>
			{children}
		</CPopup>
	)
}
