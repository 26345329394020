'use client'

import React from 'react'

import * as MdIcons from 'react-icons/md';
import {
	Icon as CIcon,
} from 'konsta/react';

export default function Icon({ name, size, className, children, ...rest }) {
	return (
		<CIcon className={className} material={React.createElement(MdIcons[name] ?? MdIcons['MdWarning'], {className: `${size ? size : 'w-8 h-8'}`})} />
	)
}
