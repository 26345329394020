'use client'

import React, { useEffect } from 'react';

import {
    Notification as CNotification,
} from 'konsta/react';

import Bugsnag from '@bugsnag/js';

export default function Notification({ opened, setOpened, timeout = 1500, closeCallback = () => { }, children, className = '', ...rest }) {
    
    useEffect(() => {
        let timer;
        if (opened) {
            timer = setTimeout(() => {
                setOpened(false);

                try {
                    closeCallback();
                } catch (error) {
                    const HandledError = new Error(`Catched: ${(typeof error === 'string') ? error : error.message}`);
                    Bugsnag.notify(HandledError);
                }
            }, timeout);
        }

        return () => clearTimeout(timer);
    }, [opened]);

    useEffect(() => {
        opened && setOpened(true);
    }, []);

	return (
		<CNotification {...rest} opened={opened} className={`fixed left-4 right-4 h-fit ${className}`} style={{zIndex: 100, top: "5rem", boxShadow: "0 0 10px rgba(0,0,0,0.2)"}}>
			{children}
		</CNotification>
	)
}
