'use client'

import {
	BlockTitle as CBlockTitle,
} from 'konsta/react';

export default function BlockTitle({ children, ...rest }) {
	return (
		<CBlockTitle {...rest}>
			{children}
		</CBlockTitle>
	)
}
