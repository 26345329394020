'use client'

import {
	Block as CBlock,
} from 'konsta/react';

export default function Block({ children, ...rest }) {
	return (
		<CBlock {...rest}>
			{children}
		</CBlock>
	)
}
