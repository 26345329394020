'use client'

import {
    ListInput as CListInput,
} from 'konsta/react';

export default function ListInput({ children, type = 'text', ...rest }) {
    let inputClassName = 'text-primary-800 dark:text-primary-800 placeholder-primary-800 dark:placeholder-primary-800';
    if (type === 'textarea') {
        inputClassName += ' min-h-32';
    }

	return (
        <CListInput
            colors={{
                bgMaterial: "bg-primary-100 dark:bg-primary-100",
                textMaterial: "text-primary-800 dark:text-primary-800",
                labelTextMaterial: "text-primary-800 dark:text-primary-800",
                labelTextFocusMaterial: "text-primary-800 dark:text-primary-800",
                outlineBorderMaterial: "border-primary-800 dark:border-primary-800",
                outlineBorderFocusMaterial: "border-primary-800 dark:border-primary-800",
            }}
            inputClassName={inputClassName}
            color='primary-800'
            type={type}
            {...rest}
        >
			{children}
		</CListInput>
	)
}
