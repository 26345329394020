'use client'

import {
    ListItem as CListItem,
} from 'konsta/react';

export default function ListItem({ children, ...rest }) {
	return (
		<CListItem {...rest}>
			{children}
		</CListItem>
	)
}
