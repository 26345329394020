'use client'

import { createContext, useState, useEffect } from 'react';

import { guestPaths } from '../constants/middlewarePaths';

import useEncryptedLocalStorage from '@/lib/hook/use-encrypted-local-storage';
import { Instance } from '@/lib/helpers/directus/instance';
import { readMe, withToken, refresh } from '@directus/sdk';
import { usePathname } from 'next/navigation'

import Loading from '@/components/Loading';
import { SDKWrapper } from '../helpers/directus/SDKWrapper';

export const AuthContext = createContext(null);

export const defaultLocalStorageObject = {
	loading: true,
	isLoggedIn: false,
}

export function AuthProvider({ children }) {
	const pathname = usePathname();
	const [localStorageObject, setLocalStorageObject, isFirstLoad, usingInitialValue, reset] = useEncryptedLocalStorage('auth', defaultLocalStorageObject);
	const [localStorageObjectLoaded, setLocalStorageObjectLoaded] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			setLocalStorageObjectLoaded(true);
		}, 50);
	}, [isFirstLoad]);

	useEffect(() => {
        if (localStorageObjectLoaded) {
            if (!usingInitialValue && localStorageObject.loading) {
				setLocalStorageObject({
					...localStorageObject,
					loading: false,
				}, 'AuthProvider loaded and usingInitialValue');
			}

            if (localStorageObject.isLoggedIn) {
                const sdkWrapper = new SDKWrapper(localStorageObject, setLocalStorageObject);

                sdkWrapper.refreshTokenIfNeeded().then(() => {
                    if (pathname === '/account/login') {
                        window.location.href = '/';
                        return;
                    }
                });
            } else {
				if (localStorageObject.loading) {
					setLocalStorageObject({
						...localStorageObject,
						loading: false,
					}, 'AuthProvider loaded and !isLoggedIn');
				}
				if (!guestPaths.includes(pathname)) {
					window.location.href = '/account/login';
				}
			}
		}
	}, [localStorageObjectLoaded]);

	return (
		<AuthContext.Provider value={{
			localStorageObject,
			setLocalStorageObject,
			isFirstLoad,
			usingInitialValue,
			reset,
		}}>
			<>
				{localStorageObject.loading ? (
					<div className='flex' style={{height: '100dvh'}}>
						<Loading />
					</div>
				) : (
					<>
						{ children }
					</>
				)}
			</>
		</AuthContext.Provider>
	);
}