'use client'

import {
	Card as CCard,
} from 'konsta/react';

export default function Card({ children, ...rest }) {
	return (
        <CCard
            {...rest}
        >
			{children}
		</CCard>
	)
}
