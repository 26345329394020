'use client'

import {
	List as CList,
} from 'konsta/react';

export default function List({ children, ...rest }) {
	return (
		<CList {...rest}>
			{children}
		</CList>
	)
}
