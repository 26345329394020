'use client'

import React from 'react'
import {
    Button,
    Card,
	Icon
} from "@/components/capacitor";

import { useRouter } from 'next/navigation';

export default function StackedCards({ cards }) {
    const router = useRouter();

	const handleCollapseCards = (index) => {
		const cardsWrapper = document.getElementById(`subSubCards-${index}`);

		cardsWrapper.classList.toggle('max-h-0');
		cardsWrapper.classList.toggle('max-h-[100000px]');
	};

	return (
		<>
			{cards.map((card, index) => (
				<div key={index} className='pb-8'>
					<Card
						colors={{
							bgMaterial: "bg-primary-300 dark:bg-primary-300",
							textMaterial: "text-primary-800 dark:text-primary-800",
						}}
						raised style={{ zIndex: 99 }} className={`relative pt-4 pb-6 mx-0`}
					>
						<div className={`flex gap-4`}>
							<div className="h-16 w-16 rounded-full bg-primary-100 text-primary-800 flex">
								<Icon className="m-auto" name={card.icon ?? 'MdDashboard'} />
							</div>
							<div className="flex-1 flex flex-col gap-y-4">
								<div>
									<div className="flex justify-between gap-x-2">
										<div className='flex-1'>
											<h4 className='mb-2'>{card.title}</h4>
											{card.subTexts.map((subText, subTextIndex) => (
												<p key={subTextIndex}>{subText}</p>
											))}
										</div>
										{card.button && (
											<Button onClick={card.button.onClick ? () => card.button.onClick() : () => {router.push(card.button.href)}} style={{ width: 'auto' }} className='flex-shrink-0'>{card.button.text}</Button>
										)}
									</div>

									{card.progress !== null && typeof card.progress !== 'undefined' && (
										<div className="relative h-3 bg-primary-100 dark:bg-primary-100 rounded-full mt-4 overflow-hidden">
											<div className="absolute h-full bg-primary-800 dark:bg-primary-800 rounded-full" style={{ width: `${card.progress}%` }}></div>
										</div>
									)}
								</div>
							</div>
						</div>
					</Card>

					{card.subCards && card.subCards.map((subCard, subCardIndex) => {
						const zIdx = card.subCards.length - subCardIndex;
						const zIdx2 = (card.subCards.length - subCardIndex) - 1;

						return (
							<React.Fragment key={subCardIndex}>
								<Card
									colors={{
										bgMaterial: "bg-primary-100 dark:bg-primary-100",
										textMaterial: "text-primary-800 dark:text-primary-800",
									}}
									raised
									style={{ zIndex: zIdx }}
									className={`${subCardIndex === 0 ? '-mt-10 pt-6' : ''} relative mx-1 cursor-pointer`}
									onClick={() => handleCollapseCards(subCardIndex)}
								>
									<div className='flex gap-4'>
										{subCard.icon && (
											<div className="h-12 w-12 rounded-full bg-primary-300 text-primary-800 flex">
												<Icon className="m-auto" name={subCard.icon} />
											</div>
										)}
										<div className="flex-1 flex flex-col gap-y-4">
											<div>
												<div className="flex justify-between gap-x-2">
													<div className='flex-1'>
														<h5 className='mb-1'>{subCard.title}</h5>
														{subCard.subTexts && subCard.subTexts.map((subText, subTextIndex) => (
															<p key={subTextIndex}>{subText}</p>
														))}
													</div>
													<div className='flex items-center gap-3'>
														{subCard.progress !== null && typeof subCard.progress !== 'undefined' && subCard.subCards.length > 0 && (
															<>
																{100 - subCard.progress === 0 ? (
																	<Icon size="size-14" className="shadow-primary rounded-full m-auto" name="MdCheckCircle" />
																) : (
																	<div className="relative size-14">
																		<svg className="size-full rotate-90 shadow-primary rounded-full" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
																			<circle cx="18" cy="18" r="15" fill="none" className="stroke-current text-white" strokeWidth="2"></circle>
																			<circle cx="18" cy="18" r="15" fill="none" className="stroke-current text-primary-800" strokeWidth="2" strokeDasharray="100" strokeDashoffset={100 - subCard.progress} strokeLinecap="round"></circle>
																		</svg>
																	
																		<div className="absolute top-1/2 start-1/2 transform -translate-y-1/2 -translate-x-1/2">
																			<span className="text-center text-sm font-semibold text-primary-800">{subCard.progress}%</span>
																		</div>
																	</div>
																)}
															</>
														)}

														{subCard.button && (
															<Button onClick={() => {router.push(subCard.button.href)}} style={{ width: 'auto' }} className='flex-shrink-0'>{subCard.button.text}</Button>
														)}
													</div>
												</div>
											</div>
										</div>
									</div>
								</Card>

								<div id={`subSubCards-${subCardIndex}`} className={`-mt-10 ${subCardIndex === 0 ? 'max-h-[100000px]' : 'max-h-0'} overflow-hidden`}>
									{subCard.subCards && subCard.subCards.map((subSubCard, subSubCardIndex) => (
										<Card
											colors={{
												bgMaterial: "bg-primary-100 dark:bg-primary-100",
												textMaterial: "text-primary-800 dark:text-primary-800",
											}}
											key={subSubCardIndex}
											raised
											style={{ zIndex: zIdx2 }}
											className={`${subSubCardIndex !== 0 ? '-mt-10' : 'mt-0'} relative ${(subSubCardIndex === 0 && subSubCardIndex === subCard.subCards.length - 1) ? 'pt-6' : subSubCardIndex === 0 ? 'py-6' : subSubCardIndex === subCard.subCards.length - 1 ? '' : 'pb-6'} mx-2`}
										>
											<div className='flex gap-4'>
												<div className="flex-1 flex flex-col gap-y-4">
													<div>
														<div className="flex justify-between gap-x-2">
															<div className='flex-1'>
																<h5 className='mb-1'>{subSubCard.title}</h5>
																{subSubCard.subTexts && subSubCard.subTexts.map((subSubText, subSubTextIndex) => (
																	<p key={subSubTextIndex}>{subSubText}</p>
																))}
															</div>
															<div className='flex items-center gap-3'>
																{subSubCard.progress !== null && typeof subSubCard.progress !== 'undefined' && (
																	100 - subSubCard.progress === 0 ? (
																		<Icon size="size-12" className="shadow-primary rounded-full m-auto" name="MdCheckCircle" />
																	) : (
																		<div className="relative size-12">
																			<svg className="size-full rotate-90 shadow-primary rounded-full" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
																				<circle cx="18" cy="18" r="15" fill="none" className="stroke-current text-white" strokeWidth="2"></circle>
																				<circle cx="18" cy="18" r="15" fill="none" className="stroke-current text-primary-800" strokeWidth="2" strokeDasharray="100" strokeDashoffset={100 - subSubCard.progress} strokeLinecap="round"></circle>
																			</svg>
																		
																			<div className="absolute top-1/2 start-1/2 transform -translate-y-1/2 -translate-x-1/2">
																				<span className="text-center text-xs font-semibold text-primary-800">{subSubCard.progress}%</span>
																			</div>
																		</div>
																	)
																)}

																{subSubCard.button && (
																	<Button onClick={() => {router.push(subSubCard.button.href)}} style={{ width: 'auto' }} className='flex-shrink-0'>{subSubCard.button.text}</Button>
																)}
															</div>
														</div>
													</div>
												</div>
											</div>
										</Card>
									))}
								</div>
							</React.Fragment>
						)
					})}
				</div>
			))}
		</>
	)
}
