'use client'

import {
	Button as CButton,
} from 'konsta/react';

export default function Button({ colorType = 'primary', className = '', children, ...rest }) {
    let bgClassnames = 'bg-primary-800 dark:bg-primary-800 hover:bg-primary-500 dark:hover:bg-primary-500';
    let textClassnames = 'text-primary-100 dark:text-primary-100 hover:text-primary-200 dark:hover:text-primary-200';
    if (colorType === 'danger') {
        bgClassnames = 'bg-danger-400 dark:bg-danger-400 hover:bg-danger-300 dark:hover:hover:bg-danger-300';
        textClassnames = 'text-danger-100 dark:text-danger-100 hover:text-danger-700 dark:hover:text-danger-700';
    } else if (colorType === 'wrong') {
        bgClassnames = 'bg-red-300 dark:bg-red-300 hover:bg-red-200 dark:hover:bg-red-200';
        textClassnames = 'text-primary-800 dark:text-primary-800 hover:text-red-700 dark:hover:text-red-700';
    } else if (colorType === 'right') {
        bgClassnames = 'bg-primary-300 dark:bg-primary-300 hover:bg-primary-400 dark:hover:bg-primary-400';
        textClassnames = 'text-primary-800 dark:text-primary-800 hover:text-green-100 dark:hover:text-green-100';
    }
    
    return (
        <CButton
            colors={{
                fillBgMaterial: bgClassnames,
                fillTextMaterial: textClassnames,
            }}
            className={`transition-all duration-300 ${className} disabled:opacity-50 disabled:select-none disabled:cursor-not-allowed`}
            {...rest}
        >
			{children}
		</CButton>
	)
}
