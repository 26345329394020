import { useEffect, useState } from "react";
import { decryptData, encryptData } from "@/lib/helpers/EncryptionHelper";
import Bugsnag from '@bugsnag/js';

export default function useEncryptedLocalStorage(
	key,
	initialValue
) {
	const [isFirstLoad, setIsFirstLoad] = useState(true);
	const [usingInitialValue, setUsingInitialValue] = useState(true);
	const [storedValue, setStoredValue] = useState(initialValue);

    const setStoredValueWrapper = (value, from = 'not-defined') => {
		setUsingInitialValue(false);
		setStoredValue(value);
	}

	useEffect(() => {
		if (isFirstLoad) {
			const encryptedStoredValue = window.localStorage.getItem(key);
			const item = encryptedStoredValue ? decryptData(encryptedStoredValue) : null;

			let itemInitial = window.localStorage.getItem(`${key}Inital`) ?? 'true';
			try {
				if (itemInitial) {
					itemInitial = JSON.parse(itemInitial);
				}
			} catch (error) {
                const HandledError = new Error(`Catched: ${(typeof error === 'string') ? error : error.message}`);
                Bugsnag.notify(HandledError);
				itemInitial = true;
			}

            if (item) {
                try {
                    // item.loading = false;
					setUsingInitialValue(itemInitial);
					setStoredValue(JSON.parse(item));
				} catch (error) {
					const HandledError = new Error(`Catched: ${(typeof error === 'string') ? error : error.message}`);
					Bugsnag.notify(HandledError);
					setUsingInitialValue(true);
					setStoredValue(initialValue);
				}
            } else {
                setUsingInitialValue(true);
                setStoredValue(initialValue);
            }
			setIsFirstLoad(!isFirstLoad);
		}
	}, [key, isFirstLoad]);

	useEffect(() => {
		if (!isFirstLoad) {

			const encryptedStoredValue = encryptData(JSON.stringify(storedValue));
			if (usingInitialValue) {
				window.localStorage.setItem(`${key}Inital`, JSON.stringify(true));
			} else {
				window.localStorage.setItem(`${key}Inital`, JSON.stringify(false));
			}
			window.localStorage.setItem(key, encryptedStoredValue);
		}
	}, [storedValue, key, isFirstLoad]);


	function reset() {
		window.localStorage.removeItem(key);
		// window.localStorage.removeItem(`${key}Inital`);
		window.localStorage.setItem(`${key}Inital`, JSON.stringify(true));
		setIsFirstLoad(true);
		setUsingInitialValue(true);
		setStoredValue(initialValue);
	}

	return [storedValue, setStoredValueWrapper, isFirstLoad, usingInitialValue, reset];
}