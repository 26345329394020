'use client'

import {
	Checkbox as CCheckbox,
} from 'konsta/react';

export default function Checkbox({ children, ...rest }) {
	return (
        <CCheckbox
            className='text-primary-800 dark:text-primary-800'
            colors={{
                labelTextMaterial: "text-primary-800 dark:text-primary-800",
                labelTextFocusMaterial: "text-primary-800 dark:text-primary-800",
                borderCheckedMaterial: "border-primary-800 dark:border-primary-800",
                bgCheckedMaterial: "bg-primary-800 dark:bg-primary-800 text-primary-100 dark:text-primary-100",
            }}
            {...rest}
        />
	)
}
